import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Community'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Community`}</h1>
    <p>{`The Arwes has grown to have a community of nice and passionate people in
`}<a parentName="p" {...{
        "href": "https://discord.gg/s5sbTkw"
      }}>{`Discord`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes"
      }}>{`GitHub`}</a>{`,
and `}<a parentName="p" {...{
        "href": "https://twitter.com/arwesjs"
      }}>{`Twitter`}</a>{`.`}</p>
    <p>{`¡Join us in `}<a parentName="p" {...{
        "href": "https://discord.gg/s5sbTkw"
      }}>{`Discord`}</a>{` to follow the development,
share ideas, and talk about futuristic science fiction!`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/community/code-of-conduct"
      }}>{`Code of Conduct`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Proper attribution of contributions and project showcase is still in progress.`}</em></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      